import { Navbar, Container, Nav, Button} from 'react-bootstrap';
import { FaReact, FaJs, FaLess, FaSass, FaCss3, FaGit, FaNode,} from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  
 
  return (
    <div className="App">
      <Navbar bg="light" expand="lg">
  <Container>
    <Navbar.Brand className="Carlos" href="#home">CARLOS MITCHELL</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-left">
        <Nav.Link href="#home">Home</Nav.Link>
        {/* <Nav.Link href="#link">Projects</Nav.Link> */}
        <Nav.Link href="https://www.overleaf.com/read/cmfcvhzbkwzx" target="_blank">Resume</Nav.Link>
        
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
<Container className="About">
  <Container className='Hero-container'>
    <Container className="Myname">
  <h1 className="myNameh1">Hello,  I'm Carlos Mitchell.</h1>
  <h1 className="myNameh1">Software Engineer.</h1>   
  </Container>
  <Container className="Icons">
<FaReact className="FaReact"/>
                <FaJs className="FaJs"/> 
                <FaLess className="FaLess"/> 
                <FaSass className="FaSass"/>
                <FaCss3 className="FaCss3"/>
                <FaGit className="FaGit"/>
                <FaNode className="FaNode"/>
  </Container>
  </Container>
  
  <Container className="Aboutme">
    <h2 className="h2-title">About</h2>
    <p className="p-about">
    I'm a Full Stack Engineer specializing in JavaScript, ReactJS, NodeJS, SQL GIT.</p> 
    <p className="p-about">My background in consulting and sciences sharpened my ability to analyze and </p>
    <p className="p-about">solve complex tasks in a methodical way, at the same time understand customer needs.</p>
  </Container>
  <Container className="Skills">
    <h2 className="h2-title">My Skills</h2>
    <p className="p-about">•JavaScript •HTML •CSS/Sass/LESS
      •React •Redux •Python •LATEX
      •Node •Git </p>
      <p className="p-about">•npm/Yarn •Express
      •RESTfulAPIs •SQL •PostgreSQL •Jest
      •Debugging</p>
      <p className="p-about">•JSON•Linux
      •DataStructures •Algorithms •Agile
       Familiar:
      •MySQL•Mapbox•ChartJ</p>
  </Container>
  {/* <Container className="Skills">
    <h2 className="h2-title">Projects</h2>
    
  </Container> */}
  <Container className="Contactme">
    <h2 className="h2-title">Contact Me</h2>
    <p className="p-about">
    Feel free to reach out if you're looking for a developer, have a question, or just want to connect.
    </p>
    <Button variant="primary" href="https://docs.google.com/forms/d/e/1FAIpQLSeb4cDVxZFYtaYTt2SBzMLftMqy0vU17VXzgQHPPYMcwKlz8w/viewform?usp=sf_link" target="_blank" style={{width:"157px"}}>Form</Button>{' '}
    <a className= "C-email" href="mailto:carlos.mitchell.dev@gmail.com">carlos.
    mitchell.dev@gmail.com</a>
  </Container>
  <Container className="B-nav">
  <Nav className="justify-content-center"  activeKey="/home">
    <Nav.Item>
      <Nav.Link href="/" style={{color:"rgb(105, 105, 105)"}}>Home</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link eventKey="link-1" style={{color:"rgb(105, 105, 105)"}} href="https://www.overleaf.com/read/cmfcvhzbkwzx" target="_blank">Resume</Nav.Link>
    </Nav.Item>
  </Nav>
  </Container>
  <Container className="Madeby">
    <h5>Made by Carlos</h5>
  </Container>
</Container>
    </div>
  );
}

export default App;
